<template>
  <ul class="list-unstyled mb-0">
    <b-media
      v-for="(el, i) in list"
      :key="i"
      tag="li"
      :class="i < list.length - 1 ? 'mb-3 mb-lg-4 mb-xl-5' : ''"
    >
      <template #aside>
        <a
          :href="el.url"
          target="_blank"
          class="c-news-list-img"
          :style="{ backgroundImage: `url('${el.thumb}')` }"
        />
      </template>
      <h6 class="c-news-list-title mb-2 mb-lg-3">
        <a :href="el.url" target="_blank">{{ el.title }}</a>
      </h6>
      <p class="c-news-list-date">{{ el.updatetime }}</p>
    </b-media>
  </ul>
</template>

<script>
export default {
  name: "BaseNewsList",
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.c-news-list {
  &-img {
    display: block;
    width: 113px;
    height: 113px;
    overflow: auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &-title {
    line-height: 1.5;
  }

  &-date {
    color: $secondary;
  }

  @include media-breakpoint-up(sm) {
    &-img {
      width: 200px;
    }

    &-title {
      font-size: $h5-font-size;
    }
  }

  @include media-breakpoint-up(md) {
    &-img {
      width: 250px;
      height: 141px;
    }
  }

  @include media-breakpoint-up(lg) {
    &-img {
      width: 300px;
      height: 170px;
    }
  }

  @include media-breakpoint-up(xl) {
    &-img {
      width: 350px;
      height: 198px;
    }

    &-title {
      font-size: $h4-font-size;
    }

    &-date {
      font-size: $font-size-lg;
      color: $secondary;
    }
  }
}
</style>
